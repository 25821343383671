/* eslint-disable max-lines */
import { gql } from '@apollo/client/core'

import {
  ADDRESS_ATTRIBUTES,
  PAGE_INFO_ATTRIBUTES,
  RENTAL_BASE_ATTRIBUTES,
  RENTAL_STATUS_ATTRIBUTES,
  SUB_ITEM_BASE_ATTRIBUTES
} from '../fragments'

export const FETCH_RENTAL_PRODUCTS = gql`
  query fetchProducts($id: ID!, $lineItemType: [LineItemTypeEnum!], $scopes: [LineItemScope!]) {
    lineItems(rentalId: $id, lineItemType: $lineItemType, scopes: $scopes) {
      id
      name
      lineItemType
      startDate
      returnDate
      checkupEnabled
      ... on LineItem {
        vacant
        machineStatus
        category {
          checkupDays
        }
      }
      ... on SubItem {
        vacant
        machineStatus
        freeAttachment
        lineItemId
      }
    }
    rental(id: $id) {
      id
      project {
        name
      }
    }
  }
`

export const PAGINATED_RENTAL_QUERY = gql`
  query rentals(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $query: String
    $isRequested: Boolean
    $runningRentals: Boolean
    $excludeCanceled: Boolean
    $scope: String
    $projectId: ID
    $sort: [SortOptions!] = [{ sortField: "created_at", sortDirection: "desc" }]
  ) {
    rentals(
      first: $first
      last: $last
      after: $after
      before: $before
      query: $query
      isRequested: $isRequested
      runningRentals: $runningRentals
      excludeCanceled: $excludeCanceled
      scope: $scope
      projectId: $projectId
      sort: $sort
    ) {
      nodes {
        ...rentalStatusAttributes
        ...rentalBaseAttributes
        displayPrice
        project {
          name
        }
        products {
          name
        }
      }
      pageInfo {
        ...pageInfoAttributes
      }
      totalCount
      pageIndex
    }
  }
  ${PAGE_INFO_ATTRIBUTES}
  ${RENTAL_STATUS_ATTRIBUTES}
  ${RENTAL_BASE_ATTRIBUTES}
`

export const RENTAL_COUNT_QUERY = gql`
  query rentals($isRequested: Boolean, $scope: String) {
    rentals(isRequested: $isRequested, scope: $scope) {
      totalCount
    }
  }
`

export const FETCH_RENTAL_ADDRESS = gql`
  query fetchRentalAddress($id: ID!) {
    rental(id: $id) {
      id
      deliveryAddress {
        ...addressAttributes
      }
    }
  }
  ${ADDRESS_ATTRIBUTES}
`

export const RENTAL_SHOW_QUERY = gql`
  query fetchRental($id: ID!) {
    rental(id: $id) {
      ...rentalStatusAttributes
      ...rentalBaseAttributes
      longitude
      latitude
      priceNetTotal
      priceTaxTotal
      priceProducts
      priceInsurance
      priceDelivery
      priceAdditional
      discountPrice
      priceGrossTotal
      priceGrossTotalWithDeposit
      totalPaid
      deposit
      quotedOrPast
      damagesCount
      contact {
        name
      }
      deliveryAddress {
        ...addressAttributes
      }
      managerContact {
        name
      }
      deliveryContact {
        name
      }
      confirmationSnapshot {
        url
      }
      lastQuote {
        url
        valid
      }
      customerOrderNumber
      project {
        id
        name
        address {
          ...addressAttributes
        }
      }
      productsWithSubItems {
        ... on LineItem {
          id
          name
          machineStatus
          categorySegment {
            name
          }
          startDate
          returnDate
          startTime
          returnTime
          discountedUnitPrice
          filterStatus
          checkupEnabled
        }
        ... on SubItem {
          id
          name
          machineStatus
          categorySegment {
            name
          }
          ...subItemBaseAttributes
        }
      }
      deliveries {
        name
        cargos {
          name
        }
        deliveryDate
        discountedUnitPrice
      }
    }
  }
  ${SUB_ITEM_BASE_ATTRIBUTES}
  ${RENTAL_STATUS_ATTRIBUTES}
  ${ADDRESS_ATTRIBUTES}
  ${RENTAL_BASE_ATTRIBUTES}
`

export const RENTAL_LINE_ITEMS_QUERY = gql`
  query fetchRentalLineItems($id: ID!) {
    rental(id: $id) {
      id
      priceNetTotal
      priceGrossTotal
      priceTaxTotal
      deliveryMethod
      lineItems {
        id
        name
        discountedUnitPrice
        lineItemType
        lineItemId
      }
      deliveries {
        name
        cargos {
          name
        }
        discountedUnitPrice
      }
    }
  }
`

export const FETCH_RENTAL_FOR_DAMAGES = gql`
  query fetchRentalBase($id: ID!) {
    rental(id: $id) {
      id
      rentalCode
      project {
        name
      }
    }
  }
`

export const CANCEL_REQUEST = gql`
  mutation cancelRequest($input: CancelRequestInput!) {
    cancelRequest(input: $input) {
      errors {
        message
      }
    }
  }
`
